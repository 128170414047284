// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import parsley from 'parsleyjs'
import 'parsleyjs/src/i18n/pt-pt.js'
import select2 from 'select2/dist/js/select2.full.js'
import 'cocoon'
import Cookies from 'js-cookie'
import 'bootstrap/dist/js/bootstrap'
import 'jquery-steps/build/jquery.steps.min'
import Chart from 'chart.js'
import sortable from '../src/html5sortable/html5sortable.cjs.js'
import '../src/dashforge/dashforge.js'
import '../src/dashforge/dashforge.aside.js'
import '../src/operation_periods_calculations.js'

import "@fortawesome/fontawesome-free/css/all"

window.createClient = function (name) {
  var newOption = new Option(name, -1, false, false);
  $('.client-select2').append(newOption).trigger('change');
  $('.client-select2').val(-1).trigger('change');
  $('.client-select2').select2('close');
  $("#plant_new_client_name").val(name);
}

window.createSubcontractor = function (name) {
  var newOption = new Option(name, -1, false, false);
  $('.subcontractor-select2').append(newOption).trigger('change');
  $('.subcontractor-select2').val(-1).trigger('change');
  $('.subcontractor-select2').select2('close');
  $("#plant_new_subcontractor_name").val(name);
}

window.createReference = function (name, element_id) {  
  var newOption = new Option(name, -1, false, false);
  $("#"+element_id).append(newOption).trigger('change');
  $("#"+element_id).val(-1).trigger('change');
  $("#"+element_id).select2('close');
  $("#"+element_id).val(name);
}

window.createIssue = function (name) {
  var newOption = new Option(name, -1, false, false);
  $('.issue-select').append(newOption).trigger('change');
  $('.issue-select').val(-1).trigger('change');
  $('.issue-select').select2('close');
  $("#incident_new_issue_name").val(name);
}

window.createSolution = function (name) {
  var newOption = new Option(name, -1, false, false);
  $('.solution-select').append(newOption).trigger('change');
  $('.solution-select').val(-1).trigger('change');
  $('.solution-select').select2('close');
  $("#incident_new_solution_name").val(name);
}

window.initReferenceForm = function() {  
  $('.reference-form').on('shown.bs.modal', function (e) {
    $('.select2').each(function( ) {
      var parent = $(this).data('select2parent');
      if (parent == null) { parent = "body" }

      $(this).select2({
        dropdownParent: $(parent),
        dropdownCssClass: 'inside-modal'
      });
    });
  })
}

window.initAdminForm = function() {  
  $('.admin-form').on('shown.bs.modal', function (e) {
    $('.select2').select2({
      dropdownCssClass: 'inside-modal'
    });
  })
}

window.initReferenceSelect = function () {
  $('.reference-select2').select2({
    tags: true,
    createTag: function (params) {
      var element_id = $(this)[0].$element.attr("id");
      // console.log($("#"+element_id));  
      $("#"+element_id).siblings(".hidden-value-container").val(params.term);
      // console.log($("#"+element_id).siblings(".hidden-value-container"));
      return {
        id: "-1",
        text: params.term,
        newTag: true // add additional parameters
      }
    }
  });
}

window.initInverterBrandSelect = function () {
  $(".inverter-brand-select").on('change', function(evt){
    $.get( "/inverter_brands/inverter_models", {"id": $(this).val()}, function(data) {
      var target = $(evt.target).parent().parent().find(".inverter-model-select");       
      $(target).empty().trigger("change");
      var obj = JSON.parse(data);
      obj.forEach(function(item){
        var newOption = new Option(item.name, item.id, false, false);
        $(target).append(newOption).trigger('change');
      })
    })
    .fail(function() {
      alert( "Ocorreu um erro ao carregar modelos de inversores." );
    })    
  });
}

window.initModuleBrandSelect = function () {
  $(".module-brand-select").on('change', function(evt){
    $.get( "/module_brands/module_models", {"id": $(this).val()}, function(data) {
      var target = $(evt.target).parent().parent().find(".module-model-select");       
      $(target).empty().trigger("change");
      var obj = JSON.parse(data);
      obj.forEach(function(item){
        var newOption = new Option(item.name, item.id, false, false)
        newOption.setAttribute('data-power', item.power);
        $(target).append(newOption).trigger('change');
      })
    })
    .fail(function() {
      alert( "Ocorreu um erro ao carregar modelos de módulos fotovoltaicos." );
    })    
  });
}

window.initModuleModelSelect = function () {
  $(".module-model-select").on("change", function(){
    $(this).parent().parent().find(".input-string-power").val($(this).find("option:selected").data("power"));
  });
}

window.initParsleyModalForms = function () {
  $('.parsley-form-modal').on('shown.bs.modal', function (e) {
    $("form.parsley-form").parsley();
  });
}

window.initRemoteModalForms = function () {
  $('a.remote-modal-form').off().on('click', function(){
    var modalSelector = $(this).data("target");
    $($(this).data("target")+' .modal-dialog').load($(this).data("remote"), function(response, status, xhr){ 
      if(xhr.status == 200){
        feather.replace();
        initCocoonForms();
        $("#operation_period_rejected").on("change", function(){
          if($(this).is(":checked")){
            $("#operation_period_real_production, #operation_period_radiation, #operation_period_real_pr, #operation_period_estimated_pr, #operation_period_guaranteed_pr, #operation_period_estimated_deviation, #operation_period_guaranteed_deviation").val("0");
          }
        });
      } else if(xhr.status == 403) {
        createToast("Erro", response);
        $(modalSelector).on('shown.bs.modal', function (e) {
          $(modalSelector).modal('hide');
        })
      }
    });
  });
}

window.initFileInputs = function () {
  $('.custom-file-input').on('change',function(e){
    var fileName = e.target.files[0].name;
    var nextSibling = e.target.nextElementSibling;
    nextSibling.innerText = fileName
  })
}

window.initDashboardChart1 = function (data) {
  console.log(data);
  // Stacked Chart
  var ctx = $('#incidentsChart');
  var stackedBar = new Chart(ctx, {
    type: 'bar',
    data: {
      labels: data.dates,
      datasets: [{
        label: "Criados",
        data: data.opened,
        backgroundColor: '#0168fa'
      }, {
        label: "Resolvidos",
        data: data.closed,
        backgroundColor: '#69b2f8'
      }]
    },
    options: {
      maintainAspectRatio: false,
      responsive: true,
      legend: {
        display: false,
        labels: {
          display: false
        }
      },
      scales: {
        yAxes: [{
          stacked: true,
          gridLines: {
            color: '#e5e9f2'
          },
          ticks: {
            beginAtZero:true,
            fontSize: 10,
            fontColor: '#182b49'
          }
        }],
        xAxes: [{
          stacked: true,
          gridLines: {
            display: false
          },
          barPercentage: 0.6,
          ticks: {
            beginAtZero:true,
            fontSize: 11,
            fontColor: '#182b49'
          }
        }]
      }
    }
  });
}

window.initDashboardChart2 = function (data) {
  console.log(data);
  // Stacked Chart
  var ctx = $('#incidentsChartImpact');
  var stackedBar = new Chart(ctx, {
    type: 'bar',
    data: {
      labels: data.labels,
      datasets: [{
        label: "Incidentes",
        data: data.dataset,
        backgroundColor: '#0168fa'
      }]
    },
    options: {
      maintainAspectRatio: false,
      responsive: true,
      legend: {
        display: false,
        labels: {
          display: false
        }
      },
      scales: {
        yAxes: [{
          stacked: true,
          gridLines: {
            color: '#e5e9f2'
          },
          ticks: {
            beginAtZero:true,
            fontSize: 10,
            fontColor: '#182b49'
          }
        }],
        xAxes: [{
          stacked: true,
          gridLines: {
            display: false
          },
          barPercentage: 0.6,
          ticks: {
            beginAtZero:true,
            fontSize: 11,
            fontColor: '#182b49'
          }
        }]
      }
    }
  });
}

window.initCocoonForms = function () {
  // event handling for all cocoon inserted items
  $('form').on('cocoon:after-insert', function (e, insertedItem, originalEvent) {
    // $('select.select2').select2({width: '100%'});
    $(insertedItem).find("select.select2").each( function(){
      var parent = $(this).data('select2parent');
      if (parent == null) { parent = "body" }
      $(this).select2({
        width: '100%',
        dropdownParent: $(parent)
      });
    });
    feather.replace();
    initReferenceSelect();
    initInverterBrandSelect();
    initModuleBrandSelect();
    initModuleModelSelect();
    initDisablerCheckboxes();
    $(insertedItem).find(".input-string-modules").on("change", function(){
      if($(this).val() == 0){
        $(this).parents(".input-string-row").find(".input-string-brand").removeAttr("required");
        $(this).parents(".input-string-row").find(".input-string-model").removeAttr("required");
        $(this).parents(".input-string-row").find(".input-string-power").removeAttr("required");
        $(this).parents(".input-string-row").find(".input-string-azimuth").removeAttr("required");
        $(this).parents(".input-string-row").find(".input-string-tilt").removeAttr("required");
      } else {
        $(this).parents(".input-string-row").find(".input-string-brand").attr("required", "required");
        $(this).parents(".input-string-row").find(".input-string-model").attr("required", "required");
        $(this).parents(".input-string-row").find(".input-string-power").attr("required", "required");
        $(this).parents(".input-string-row").find(".input-string-azimuth").attr("required", "required");
        $(this).parents(".input-string-row").find(".input-string-tilt").attr("required", "required");
      }
    })
  });
}

window.initDisablerCheckboxes = function () {
  $("input[data-disables]").on("change", function(){
    if($(this).is(':checked')){
      $($(this).data("disables")).removeAttr("disabled");
    } else {
      $($(this).data("disables")).val(null);
      $($(this).data("disables")).attr("disabled", "disabled");
    }
  });
}

window.createToast = function (title, text) {
  var time = new Date().toLocaleTimeString('en-US', { hour12: false, hour: "numeric", minute: "numeric"});
  $("<div class=\"toast-container pos-absolute b-10 r-10\"><div class=\"toast\" role=\"alert\" aria-live=\"assertive\" aria-atomic=\"true\" data-autohide=\"false\"><div class=\"toast-header\"><h6 class=\"tx-inverse tx-14 mg-b-0 mg-r-auto\">" + title + "</h6><small>" + time + "</small><button type=\"button\" class=\"ml-2 mb-1 close tx-normal\" data-dismiss=\"toast\" aria-label=\"Close\"><span aria-hidden=\"true\">&times;</span></button></div><div class=\"toast-body\">" + text + "</div></div></div>").prependTo("body");
  $('.toast').toast('show');
}

window.initDatatable = function (element, columns, columndefs, paging, search, info, default_order, card_header, wrapper_id) {

  $(element).on('init.dt', function () {
    $(card_header).appendTo("#"+wrapper_id + " .row:first .col-sm-12:first");
  }); 

  var dt = $(element).DataTable({
    language: {
      searchPlaceholder: 'Filtrar listagem...',
      sEmptyTable:   "Não foram encontrados registos",
      sLoadingRecords: "A carregar...",
      sProcessing:   "A processar...",
      sLengthMenu:   "Mostrar _MENU_ registos",
      sZeroRecords:  "Não foram encontrados resultados",
      sInfo:         "A listar _END_ registos.", //"A listar de _START_ até _END_ de _TOTAL_ registos",
      sInfoEmpty:    "A listar de 0 até 0 de 0 registos",
      sInfoFiltered: "(filtrados de _MAX_ registos no total).",
      sInfoPostFix:  "",
      sSearch:       "",
      sUrl:          "",
      oPaginate: {
          sFirst:    "Primeiro",
          sPrevious: "Anterior",
          sNext:     "Seguinte",
          sLast:     "Último"
      },
      oAria: {
          sSortAscending:  ": Ordenar colunas de forma ascendente",
          sSortDescending: ": Ordenar colunas de forma descendente"
      }
    },
    lengthChange: false,
    paging: paging,
    info: info,
    searching: search,
    columns: columns,
    columnDefs: columndefs,
    order: default_order,
    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" + "<'row'<'col-sm-12'tr>>" + "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>"
  });
  
  $('.datatable').on( 'draw.dt', function () {
    console.log("draw.dt");
    feather.replace();
  })

}

window.initHideableRows = function (){
  $("a.hide-row-trigger").on("click", function(){
    var row_id = $(this).parents("tr").data("incident-id");
    $('<input>').attr({
      type: 'hidden',
      id: 'q_id_not_in_'+row_id,
      name: 'q[id_not_in][]',
      value: row_id
    }).appendTo('.filterable-form');
    $(".filterable-form").submit();
  });
  $(".clear-hidden-rows").on("click", function(){
    $('input[name ="q[id_not_in][]"]').remove();
    $(".filterable-form").submit();
  })
}

window.initRadioCheckboxes = function (){
  $(".radio-checkbox").on("click", function(){
    var scope = $(this).data("scope");
    $('input[type="checkbox"][data-scope="' + scope + '"]').prop("checked", false);
    $(this).prop("checked", true);
  })
}

window.initConditionalRequiredInputs = function (){
  document.querySelectorAll("input[data-conditional-require='true']").forEach(function(child, index){
    parent = document.getElementById(child.dataset.conditionalRequireParentId);
    parent.addEventListener('change', function(event){
      if(event.target.checked) {
        child.required = true;
      } else {
        child.required = false;
      }
    })
  });
}

window.initConditionalDisplayedInputs = function (){
  document.querySelectorAll("[data-conditional-display='true']").forEach(function(triggerElement, index){
    var element = document.getElementById(triggerElement.dataset.conditionalDisplayElement);
    $(triggerElement).on('change', function(event){
      if (event.target.value == event.target.dataset.conditionalDisplayHidingValue){
        $(element).parents(".form-group").removeClass("hidden");
      } else {
        element.value = "";
        $(element).parents(".form-group").addClass("hidden");
      }
    });
  });
}

window.initTableHeaderRadioSelectors = function (){
  document.querySelectorAll("a.selects-all").forEach(function(link, index) {
    link.addEventListener('click', function(e) {
      console.log(e.currentTarget.dataset.selects);
      document.querySelectorAll("input[type='radio']." + e.currentTarget.dataset.selects).forEach(function(element, index){
        element.checked = true;
      });
    });
  });
}

window.initIncidentModal = function(){
  $('#incident-modal').on('shown.bs.modal', function (e) {
    console.log("incident modal shown")
    $('.issue-select').select2({
      // dropdownCssClass: 'inside-modal',
      dropdownParent: $('#incident-modal'),
      language: {
        "noResults": function (data) {
          var term = $(".select2-search__field").val();
          return `${term} não encontrado. <a href='#' onclick='createIssue(\"${term}\")'>Criar "${term}"?</a>`;
        }
      }, 
      escapeMarkup: function (markup) {
        return markup;
      }
    });
    $('.solution-select').select2({
      // dropdownCssClass: 'inside-modal',
      dropdownParent: $('#incident-modal'),
      language: {
        "noResults": function (data) {
          var term = $(".select2-search__field").val();
          return `${term} não encontrado. <a href='#' onclick='createSolution(\"${term}\")'>Criar "${term}"?</a>`;
        }
      }, 
      escapeMarkup: function (markup) {
        return markup;
      }
    });
    $('.element-select').select2({
      dropdownParent: $('#incident-modal')
    });
    $('.cause-select').select2({
      dropdownParent: $('#incident-modal')
    });
    $(".start_time_today").on("click", function(){
      var today = new Date();
      var date = today.getFullYear()+'-'+("0" + (today.getMonth() + 1)).slice(-2)+'-'+("0" + today.getDate()).slice(-2);
      var time = ("0" + today.getHours()).slice(-2) + ":" + ("0" + today.getMinutes()).slice(-2) + ":" + ("0" + today.getSeconds()).slice(-2);
      $("#incident_start_time_date").val(date);
      $("#incident_start_time_time").val(time);
    });
    $(".end_time_today").on("click", function(){
      var today = new Date();
      var date = today.getFullYear()+'-'+("0" + (today.getMonth() + 1)).slice(-2)+'-'+("0" + today.getDate()).slice(-2);
      var time = ("0" + today.getHours()).slice(-2) + ":" + ("0" + today.getMinutes()).slice(-2) + ":" + ("0" + today.getSeconds()).slice(-2);
      $("#incident_end_time_date").val(date);
      $("#incident_end_time_time").val(time);
    });
  });
}

window.initCopyToClipboard = function(){
  console.log("initCopyToClipboard");
  document.querySelectorAll("a.copy-to-clipboard").forEach(function(link, index) {
    link.addEventListener('click', async function(e) {
      console.log("copy-to-clipboard running");
      e.preventDefault();
      try {
        const element = document.querySelector(link.dataset.clipboardtarget);
        let text = element.textContent;
        await navigator.clipboard.writeText(text);
        console.log("Copied to clipboard: ", text);
      } catch (error) {
        console.error("Failed to copy to clipboard:", error);
        // Optional: Handle and display the error to the user
      }
    });
  });
}



$(document).on('turbolinks:load', function () {

  console.log("Sotecnisol Dashboard loading...");

  $('.toast').toast('show');
  $('.toast .close').on('click', function(){
    $(this).parent(".toast").toast('hide');
  });
  
  // if in edit mode, does not disable steps
  const free_mode = $('#plants-wizard').hasClass("edit-mode");
  const finish_button_text = (free_mode ? "Guardar" : "Criar" );

  $('#plants-wizard').steps({
    headerTag: 'h3',
    bodyTag: 'section',
    autoFocus: true,
    titleTemplate: '<span class="number">#index#</span> <span class="title">#title#</span>',
    enableAllSteps: free_mode,
    showFinishButtonAlways: free_mode,
    labels: {
      cancel: "Cancelar",
      current: "passo actual:",
      pagination: "Paginação",
      finish: finish_button_text,
      next: "Seguinte",
      previous: "Anterior",
      loading: "A carregar..."
    },
    onStepChanging: function (event, currentIndex, newIndex) {
      if (currentIndex < newIndex) {
        return $('.plant-wizard-form').parsley({
          errorsContainer: function (Field) {
            if(Field.$element.hasClass("client-select2") || Field.$element.hasClass("select2")){
              return Field.$element.parent(".form-group");              
            }
          },
          classHandler: function (Field) {
            if(Field.$element.hasClass("client-select2") || Field.$element.hasClass("select2")){
              return Field.$element.next(".select2-container").find(".select2-selection");              
            }
          }
        }).validate({
          group: 'wizard-p-' + currentIndex
        });
        // return true; // temporary disable
      } else {
        // Always allow step back to the previous step even if the current step is not valid.
        return true;
      }
    },
    onStepChanged: function (event, currentIndex, newIndex) {     
      $('select.select2').select2({width: '100%'});
      initReferenceSelect();
    },
    onFinishing: function (event, currentIndex) {
      var result = $('.plant-wizard-form').parsley().validate({
        group: 'wizard-p-' + currentIndex
      });
      if (result) { $("form.plant-wizard-form").submit(); }
    },
    onInit: function(event, currentIndex) {
    }
  });

  $('.select2').select2();

  $('.client-select2').select2({
    language: {
      "noResults": function (data) {
        var term = $(".select2-search__field").val();
        return `${term} não encontrado. <a href='#' onclick='createClient(\"${term}\")'>Criar cliente "${term}"?</a>`;
      }
    }, 
    escapeMarkup: function (markup) {
      return markup;
    }
  });

  $('.subcontractor-select2').select2({
    language: {
      "noResults": function (data) {
        var term = $(".select2-search__field").val();
        return `${term} não encontrado. <a href='#' onclick='createSubcontractor(\"${term}\")'>Criar subempreiteiro "${term}"?</a>`;
      }
    }, 
    escapeMarkup: function (markup) {
      return markup;
    }
  });

  $("a.input-toggle").on('click', function(){
    $($(this).data('controls')).toggleClass("hidden");
    $(this).toggleClass("open");
  });

  $("a.input-toggle-slide").on('click', function(){
    $($(this).data('controls')).slideToggle();
    $(this).toggleClass("open");
  });

  $("#plant_production_warranty").on("change", function (){
    var option = $("#plant_production_warranty option:selected").val();
    switch(option) {
      case 'energy':
        // code block
        $("div.estimated-pr").addClass("hidden");
        $("div.pac-duration").addClass("hidden");
        break;
      case 'pr':
        $("div.estimated-pr").removeClass("hidden");
        $("div.pac-duration").addClass("hidden");
        break;
      case 'pr2_option1':
        $("div.estimated-pr").addClass("hidden");
        $("div.pac-duration").removeClass("hidden");
        break;
      case 'pr2_option2':
        $("div.estimated-pr").addClass("hidden");
        $("div.pac-duration").removeClass("hidden");
        break;
      default:
        // do nothing.
    }
  });

  sortable('#contact_plants', {
    items: 'tr'
  });
  if (typeof sortable('#contact_plants')[0] != 'undefined'){
    sortable('#contact_plants')[0].addEventListener('sortupdate', function(e) {
      var dataIDList = $(this).children().map(function(index){
         $(this).find( ".position" ).text(index + 1)
         return "contact_plant[]=" + $(this).data("id");
      }).get().join("&");
      Rails.ajax({
        url: $(this).data("url"),
        type: "PATCH",
        data: dataIDList,
      });
    });
  }

  sortable('#inverters-sortable', {
    items: 'tr'
  });
  if (typeof sortable('#inverters-sortable')[0] != 'undefined'){
    sortable('#inverters-sortable')[0].addEventListener('sortupdate', function(e) {
      var dataIDList = $(this).children().map(function(index){
         $(this).find( ".position" ).text(index + 1)
         return "inverter[]=" + $(this).data("id");
      }).get().join("&");
      Rails.ajax({
        url: $(this).data("url"),
        type: "PATCH",
        data: dataIDList,
      });
    });
  }

  initInverterBrandSelect();
  initModuleBrandSelect();
  initModuleModelSelect();
  initParsleyModalForms();
  initRemoteModalForms();
  initFileInputs();
  initCocoonForms();
  initDisablerCheckboxes();
  initHideableRows();
  initRadioCheckboxes();
  initConditionalRequiredInputs();
  initConditionalDisplayedInputs();
  initIncidentModal();
  initCopyToClipboard();

  console.log("whut?");

  if($(location).attr('hash') !== undefined) {
    $($(location).attr('hash')).click();
  }

  $(".toggle-rejected").on("click", function(){
    if($(this).hasClass("showing-none")){
      $(this).removeClass("showing-none");
      $(this).find(".rejected-all").removeClass("hidden");
      $(this).find(".rejected-none").addClass("hidden");
      $("tr.rejected").removeClass("still-hidden");
    } else {
      $(this).addClass("showing-none");
      $(this).find(".rejected-all").addClass("hidden");
      $(this).find(".rejected-none").removeClass("hidden");
      $("tr.rejected").addClass("still-hidden");
    }
    
  });

  console.log("Sotecnisol Dashboard ready.");

});

Parsley.addValidator('uniqueInClass', {
  requirementType: 'string',
  validateString: function(value, requirement) {
    var inputs = document.getElementsByClassName(requirement);
    var occurrences = 0;
    for(var x = 0; x < inputs.length; x++){
      if(inputs[x].value == value){
        occurrences++;
        if(occurrences == 2) { return false; }
      }
    }
    return true;
  },
  messages: {
    'pt-pt': 'Não é único.',
    en: 'Is not unique'
  }
});

window.jQuery = jQuery;
window.$ = $;
window.feather = feather;