/************************/
/* 1. GARANTIA: ENERGIA */
/************************/
window.initOperationPeriodFormFor_energy = function() {

  $(".edit-period-dates").on('click', function(){
    $(".period-date-fields").toggleClass("d-none");
  });

  /* 1.1. PRODUÇÃO REAL */
  /* Quando o campo Produção Real é alterado, actualiza o parametro a passar para o cálculo do Desvio Estimado e do Desvio Garantido */
  var realProductionElem = document.querySelector("#operation_period_real_production");
  if (realProductionElem) {
    realProductionElem.addEventListener('change', function(){
      setElementParam(document.querySelector(".estimated-deviation-calculator"), "real_production", this.value);
      setElementParam(document.querySelector(".guaranteed-deviation-calculator"), "real_production", this.value);
    });
  }  
  
  /* 1.2. PRODUÇÃO ESTIMADA */
  /* Calcula o valor do campo Produção Estimada */
  $("a.estimated-production-calculator").on("ajax:success", function(event) {
    setElementValue(document.querySelector("#operation_period_estimated_production"), event.detail[0]);
  }).on("ajax:error", function(event){
    alert(event.detail[0]);
  });
  /* Quando o campo Produção Estimada é alterado, actualiza o parametro a passar para o cálculo da Produção Garantida e do Desvio Estimado */
  var estimatedProductionElem = document.querySelector("#operation_period_estimated_production");
  if (estimatedProductionElem) {
    estimatedProductionElem.addEventListener('change', function(){
      setElementParam(document.querySelector(".guaranteed-production-calculator"), "estimated_production", this.value);
      setElementParam(document.querySelector(".estimated-deviation-calculator"), "estimated_production", this.value);
    }); 
  }
  
  /* 1.3. PRODUÇÃO GARANTIDA */
  /* Calcula o valor do campo Produção Garantida */
  $("a.guaranteed-production-calculator").on("ajax:success", function(event) {
    setElementValue(document.querySelector("#operation_period_guaranteed_production"), event.detail[0]);
  }).on("ajax:error", function(event){
    alert(event.detail[0]);
  });
  /* Quando o campo Produção Garantida é alterado, actualiza o parametro a passar para o cálculo do Desvio Garantido */
  var guaranteedProductionElem = document.querySelector("#operation_period_guaranteed_production")
  if (guaranteedProductionElem) {
    guaranteedProductionElem.addEventListener('change', function(){  
      setElementParam(document.querySelector(".guaranteed-deviation-calculator"), "guaranteed_production", this.value);
    });
  }

  /* 1.4. DESVIO ESTIMADO */
  /* Calcula o valor do campo Desvio Estimado */
  $("a.estimated-deviation-calculator").on("ajax:success", function(event) {
    setElementValue(document.querySelector("#operation_period_estimated_deviation"), event.detail[0]);
  }).on("ajax:error", function(event){
    alert(event.detail[0]);
  });

  /* 1.5. DESVIO GARANTIDO */
  $("a.guaranteed-deviation-calculator").on("ajax:success", function(event) {
    setElementValue(document.querySelector("#operation_period_guaranteed_deviation"), event.detail[0]);
  }).on("ajax:error", function(event){
    alert(event.detail[0]);
  });
}

/***********************************/
/* 2. GARANTIA: PR: Editar período */
/***********************************/
window.initOperationPeriodFormFor_pr = function() { 

  $(".edit-period-dates").on('click', function(){
    $(".period-date-fields").toggleClass("d-none");
  });
  
  /* 2.1. PRODUÇÃO REAL */
  /* Quando o campo Produção Real é alterado, actualiza o parametro a passar para o cálculo do Desvio da Produção e do PR Real */
  var realProductionElem = document.querySelector("#operation_period_real_production");
  if (realProductionElem) {
    realProductionElem.addEventListener('change', function(){
      setElementParam(document.querySelector(".production-deviation-calculator"), "real_production", this.value);
      setElementParam(document.querySelector(".real-pr-calculator"), "real_production", this.value);
    });
  }

  /* 2.2. PRODUÇÃO ESTIMADA */
  /* Calcula o valor do campo Produção Estimada */
  $("a.estimated-production-calculator").on("ajax:success", function(event) {
    setElementValue(document.querySelector("#operation_period_estimated_production"), event.detail[0]);
  }).on("ajax:error", function(event){
    alert(event.detail[0]);
  });
  /* Quando o campo Produção Estimada é alterado, actualiza o parametro a passar para o cálculo do Desvio da Produção */
  var estimatedProductionElem = document.querySelector("#operation_period_estimated_production");
  if (estimatedProductionElem) {
    estimatedProductionElem.addEventListener('change', function(){
      setElementParam(document.querySelector(".production-deviation-calculator"), "estimated_production", this.value);
    }); 
  }

  /* 2.3. DESVIO DA PRODUÇÃO */
  /* Calcula o valor do campo Desvio da Produção */
  $("a.production-deviation-calculator").on("ajax:success", function(event) {
    setElementValue(document.querySelector("#operation_period_production_deviation"), event.detail[0]);
  }).on("ajax:error", function(event){
    alert(event.detail[0]);
  });

  /* 2.4. RADIAÇÃO */
  /* Quando o campo Radiação é alterado, actualiza o parametro a passar para o cálculo do PR Real */
  var radiationElem = document.querySelector("#operation_period_radiation");
  if (radiationElem) {
    radiationElem.addEventListener('change', function(){
      setElementParam(document.querySelector(".real-pr-calculator"), "radiation", this.value);
    }); 
  }

  /* 2.5. PR REAL */
  /* Calcula o valor do campo PR Real */
  $("a.real-pr-calculator").on("ajax:success", function(event) {
    setElementValue(document.querySelector("#operation_period_real_pr"), event.detail[0]);
  }).on("ajax:error", function(event){
    alert(event.detail[0]);
  });
  /* Quando o campo PR Real é alterado, actualiza o parametro a passar para o cálculo do Desvio Estimado e Desvio Garantido */
  var realPRElem = document.querySelector("#operation_period_real_pr");
  if (realPRElem) {
    realPRElem.addEventListener('change', function(){
      setElementParam(document.querySelector(".estimated-deviation-calculator"), "real_pr", this.value);
      setElementParam(document.querySelector(".guaranteed-deviation-calculator"), "real_pr", this.value);
    }); 
  }

  /* 2.5. PR ESTIMADO */
  /* Calcula o valor do campo PR Estimado */
  $("a.estimated-pr-calculator").on("ajax:success", function(event) {
    setElementValue(document.querySelector("#operation_period_estimated_pr"), event.detail[0]);
  }).on("ajax:error", function(event){
    alert(event.detail[0]);
  });

  /* Quando o campo PR Estimado é alterado, actualiza o parametro a passar para o cálculo do PR Garantido e do Desvio Estimado */
  var estimatedPRElem = document.querySelector("#operation_period_estimated_pr");
  if (estimatedPRElem) {
    estimatedPRElem.addEventListener('change', function(){
      setElementParam(document.querySelector(".guaranteed-pr-calculator"), "estimated_pr", this.value);
      setElementParam(document.querySelector(".estimated-deviation-calculator"), "estimated_pr", this.value);
    }); 
  }

  /* 2.6. PR GARANTIDO */
  /* Calcula o valor do campo PR Garantido */
  $("a.guaranteed-pr-calculator").on("ajax:success", function(event) {
    setElementValue(document.querySelector("#operation_period_guaranteed_pr"), event.detail[0]);
  }).on("ajax:error", function(event){
    alert(event.detail[0]);
  });

  /* Quando o campo PR Garantido é alterado, actualiza o parametro a passar para o cálculo do Desvio Garantido */
  var guaranteedPRElem = document.querySelector("#operation_period_guaranteed_pr");
  if (guaranteedPRElem) {
    guaranteedPRElem.addEventListener('change', function(){
      setElementParam(document.querySelector(".guaranteed-deviation-calculator"), "guaranteed_pr", this.value);
    }); 
  }

  /* 2.7. DESVIO ESTIMADO */
  /* Calcula o valor do campo Desvio Estimado */
  $("a.estimated-deviation-calculator").on("ajax:success", function(event) {
    setElementValue(document.querySelector("#operation_period_estimated_deviation"), event.detail[0]);
  }).on("ajax:error", function(event){
    alert(event.detail[0]);
  });

  /* 2.8. DESVIO GARANTIDO */
  /* Calcula o valor do campo Desvio Garantido */
  $("a.guaranteed-deviation-calculator").on("ajax:success", function(event) {
    setElementValue(document.querySelector("#operation_period_guaranteed_deviation"), event.detail[0]);
  }).on("ajax:error", function(event){
    alert(event.detail[0]);
  });
}

/**************************************/
/* 3. GARANTIA: PR: Criar período FAC */
/**************************************/

window.initOperationPeriodFormForNewFac = function() { 
  $("#operation_period_start_date").on("change", function (){
    $("#operation_period_end_date").val($(this).val());
  });
}

window.setElementValue = function(element, value) {
  if (element) {
    element.value = value;
    element.dispatchEvent(new Event('change'));
  }
}

window.setElementParam = function(element, paramName, paramValue) {
  if (element) {
    var params = new URLSearchParams(element.getAttribute("data-params"));
    params.set(paramName, paramValue);
    element.setAttribute("data-params", params.toString());
  }
}
